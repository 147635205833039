import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"

export default function SupportItemDetail({ data }) {
    const post = data.strapiSupportPages
    return (
        <Layout>
            <Seo title="Support" />
            <InnerHero title="Support" />
            <section className="pt-120 pb-120 support-item-detail" >
                <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String! $gameSlug: String!) {
        strapiSupportPages(slug: {eq: $slug}, game: {slug: {eq: $gameSlug}}) {
            title
            slug
            content
        }
    }
`